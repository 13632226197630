import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpGoogleAnalyticsModule, ExpComponentsSharedModule, EnvModule } from '@expertly/components';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { ExpErrorPageComponent } from './error-page.component';

@NgModule({
    declarations: [AppComponent, ExpErrorPageComponent],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routing, {
            initialNavigation: 'enabledNonBlocking',
            onSameUrlNavigation: 'reload',
        }),
        ExpGoogleAnalyticsModule,
        ExpComponentsSharedModule.forRoot(),
        EnvModule.forRoot(),
        HttpClientModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useValue: createErrorHandler(),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        HttpClient,
    ],
})
export class AppModule {}
