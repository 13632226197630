import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, BrowserTracing, instrumentAngularRouting } from '@sentry/angular-ivy';

if (environment['SENTRY_DSN']) {
    init({
        dsn: environment['SENTRY_DSN'],
        environment: environment['SENTRY_ENVIRONMENT'],
        release: environment['SENTRY_RELEASE'],
        integrations: [
            new BrowserTracing({
                routingInstrumentation: instrumentAngularRouting,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampler: (samplingContext) => {
            if (/rest\/api/.test(samplingContext?.transactionContext?.name)) {
                return environment['TRACES_SAMPLE_RATE'];
            }
            return 0;
        },
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
