import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '@expertly/components';
import { isEmpty } from 'lodash/fp';

@Component({
    selector: 'wis-root',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    loadGoogleAnalytics = false;
    tries = 0;
    constructor(private readonly envService: EnvService, private readonly http: HttpClient) {}
    ngOnInit() {
        this.initGoogleAnalytics();
        this.http
            .get('/assets/js/portal.config.json?t=' + Math.random().toString().substr(0,5), {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .subscribe((config: any) => {
                const googleAnalyticsKey = config.googleAnalyticsKey;
                if (!isEmpty(googleAnalyticsKey)) {
                    this.envService.setConfigs({ googleAnalyticsKey });
                    this.initGoogleAnalytics();
                }
            });
    }

    initGoogleAnalytics() {
        if (!isEmpty(this.envService.googleAnalyticsKey)) {
            this.loadGoogleAnalytics = true;
        } else if (this.tries <= 8) {
            this.tries++;
            setTimeout(() => {
                this.initGoogleAnalytics();
            }, 5000);
        }
    }
}
